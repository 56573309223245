<template>
  <div>
    <elevator-list :which-page="1"></elevator-list>
  </div>
</template>
<script>
import ElevatorList from "../elevator/ElevatorList.vue";

export default {
  components: { ElevatorList },
};
</script>
<style lang="scss" scoped></style>
